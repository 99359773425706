<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col md="4">
                  <v-text-field dense outlined v-model="information.full_name"
                                label="Full name"   v-on:keyup.enter="generateSignatureCode()"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field dense outlined v-model="information.preferred_pronouns"
                                label="Preferred Pronouns"  v-on:keyup.enter="generateSignatureCode()"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field dense outlined v-model="information.job_title"
                                label="Job Title"  v-on:keyup.enter="generateSignatureCode()"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field dense outlined v-model="information.email"
                                label="Email"  v-on:keyup.enter="generateSignatureCode()"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field dense outlined v-model="information.mobile"
                                label="Mobile"  v-on:keyup.enter="generateSignatureCode()"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-btn @click="generateSignatureCode" rounded color="primary" dark  class="float-right">
                    Create signature
                  </v-btn>

                </v-col>
              </v-row>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
               <v-card class="mx-auto"  v-if="email_generate_flag">
                <table cellspacing="0" cellpadding="0" border="0" style="max-width: 900px; width:800px; display: block; margin-top:20px; clear:both">
                  <tr>
                    <td width="100px" align="left" valign="top">
                      <img src="https://signatures.ameb.edu.au/ameb_logo.png" alt="AMEB Logo"  border="0" style="display: inline; float: left; margin-right: 10px" height="150px" width="80px"/>
                    </td>
                    <td align="left">
                      <table cellpadding="0" cellspacing="0" border="0" width="370" style="max-width: 370px; width:370px;">
                        <tr>
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px; color: rgb(33, 33, 33); font-weight: bold; color: rgb(33, 33, 33); "> <br/>
                            {{email_generate.full_name}} <span v-if="email_generate.preferred_pronouns">({{email_generate.preferred_pronouns}})</span>
                          </td>
                        </tr>
                        <tr v-if="email_generate.job_title">
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px; color: rgb(33, 33, 33); margin-bottom: 10px;color: rgb(33, 33, 33); display: block;">
                            {{email_generate.job_title}}</td>
                        </tr>
                        <tr>
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px; font-weight: bold; color: rgb(33, 33, 33);  "> AMEB Ltd.
                          </td>
                        </tr>
                        <tr>
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px; color: rgb(33, 33, 33); display: inline;"> Level 27, 150 Lonsdale Street
                          </td>
                        </tr>
                        <tr>
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px;color: rgb(33, 33, 33); display: inline; ">
                          </td>
                        </tr>
                        <tr>
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px; padding-bottom: 10px; color: rgb(33, 33, 33);"> Melbourne VIC 3000</td>
                        </tr>
                        <tr v-if="email_generate.mobile">
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px; color: rgb(33, 33, 33); display: inline;"> <b>Mob:</b> {{email_generate.mobile}}</td>
                        </tr>
                        <tr v-if="email_generate.email">
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px; color: rgb(33, 33, 33); display: inline;"> <b>Email: </b>
                            <a :href="'mailto:'+email_generate.email" style="color: rgb(71, 124, 204); text-decoration: none; display: inline;">{{email_generate.email}}</a>
                          </td>
                        </tr>
                        <tr>
                          <td style="margin:0;font-family: Helvetica, Arial, sans-serif; font-size: 11px; margin-bottom: 10px">
                              <span style="color: rgb(33, 33, 33); display: inline;"> <b>Web: </b>
                                <a href="http://ameb.edu.au" style="color: rgb(71, 124, 204); text-decoration: none; display: inline;">
                                www.ameb&#173;.edu&#173;.au
                              </a>
                              </span>
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-top:10px;">
                            <a style="text-decoration: none; display: inline;" href="https://twitter.com/AMEBexams" alt="tw">
                              <img width="16" style="margin-bottom:2px; border:none; display:inline;" height="16" src="https://signatures.ameb.edu.au/twitter.png" alt="twi">
                            </a>
                            <span style="white-space: nowrap; display: inline;"> <img src="https://signatures.ameb.edu.au/spacer.gif" width="2" alt=" "> </span>
                            <a style="text-decoration: none; display: inline;" href="https://www.facebook.com/AustralianMusicExaminationsBoard/" alt="Facebook">
                              <img width="16" style="margin-bottom:2px; border:none; display:inline;" height="16" src="https://signatures.ameb.edu.au/facebook.png" alt="fb">
                            </a>
                            <span style="white-space: nowrap; display: inline;"> <img src="https://signatures.ameb.edu.au/spacer.gif" width="2" alt=" "> </span>
                            <a style="text-decoration: none; display: inline;" href="http://www.youtube.com/user/AMEBFedOffice">
                              <img width="16" style="margin-bottom:2px; border:none; display:inline;" height="16" src="https://signatures.ameb.edu.au/youtube.png" alt="Youtube"></a>
                            <span style="white-space: nowrap; display: inline;"> <img src="https://signatures.ameb.edu.au/spacer.gif" width="2" alt=" "> </span>
                            <a style="text-decoration: none; display: inline;" href="https://www.linkedin.com/company/australian-music-examinations-board">
                              <img width="16" style="margin-bottom:2px; border:none; display:inline;" height="16" src="https://signatures.ameb.edu.au/linkedin.png" alt="Linkedin"></a>
                            <span style="white-space: nowrap; display: inline;"> <img src="https://signatures.ameb.edu.au/spacer.gif" width="2" alt=" "> </span>
                            <a style="text-decoration: none; display: inline;" href="https://www.instagram.com/ameb_edu/"> <img width="16" style="margin-bottom:2px; border:none; display:inline;" height="16" src="https://signatures.ameb.edu.au/instagram.png" alt="inst"></a>
                            <span style="white-space: nowrap; display: inline;"> <img src="https://signatures.ameb.edu.au/spacer.gif" width="2" alt=" "> </span>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td align="left" valign="middle">
                      <a href="https://www.ameb.edu.au/yugambeh-youth-choir-donations"> <img src="https://signatures.ameb.edu.au/side_logo.png" alt="Australian Music Examination Board (AMEB)" border="0" style="display: inline; float: right;" width="100px"></a></td></tr><tr><td colspan="3" style="padding-top: 10px"> <a href="http://ameb.edu.au/email-signature-banner-redirect">
                                <img :src="email_generator_image" style="width: 850px"></a>
                          </td>
                      </tr>
                  <tr>
                    <td colspan="3" style="padding-top: 10px"><p style="font-size: 10px; font-family: Helvetica, Arial, sans-serif; text-align: justify;"> Disclaimer: This message contains confidential information and is intended only for the addressee. If you are not the addressee you should not disseminate, distribute or copy this email. <br>
                  Please notify {{this.email_generate.email}} immediately by e-mail if you have received this e-mail by mistake and delete this e-mail from your system. Email transmission cannot be guaranteed to be <br> secure or error-free as information could be intercepted, corrupted, destroyed, arrive late, be incomplete, or contain viruses.</p>
                </td>
                </tr>
                </table>
              </v-card>

            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import SettingService from "@/services/setting/SettingService";
const setting=new SettingService();
export default {
  data() {
    return{
      isLoading:false,
      email_generator_image:null,
      email_generate_flag:false,
      email_generate:{
        full_name:'',
        preferred_pronouns:'',
        job_title:'',
        email:'',
        mobile:'',
      },
      currentUser:{},
      information:{
        full_name:'',
        preferred_pronouns:'',
        job_title:'',
        email:'',
        mobile:'',
      },
    }
  },
  methods:{
    getEmailGeneratorImage(){
      setting
      .findSettingData({'name':'email_generator_image'})
          .then((response) => {
            this.email_generator_image=response.data.setting.value;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    generateSignatureCode(){
      this.getEmailGeneratorImage();

      this.email_generate_flag=true;
      this.email_generate.mobile=this.information.mobile;
      this.email_generate.email=this.information.email;
      this.email_generate.full_name=this.information.full_name;
      this.email_generate.job_title=this.information.job_title;
      this.email_generate.preferred_pronouns=this.information.preferred_pronouns;
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
      this.information.full_name=this.currentUser.full_name;
      this.information.email=this.currentUser.email;
    },
  },
  mounted() {
  this.getCurrentUser();

  },
  computed:{

  },
}
</script>